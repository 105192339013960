@charset "UTF-8";
/* ––––––––––––––––––––––––––––––––––––––––––––––––––
下層ページ共通レイアウト
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.page_default {
  padding: 0 30px; }
  .page_default h2 {
    color: #0089d1;
    font-size: 2.2rem;
    font-weight: 600;
    margin-bottom: 20px;
    text-align: center; }
    .page_default h2 span {
      background: linear-gradient(0deg, transparent 15%, white 15%, #fff000 15%, #fff000 30%, transparent 30%);
      padding: 0 10px; }
  .page_default dl.company_dl dt {
    font-weight: bold; }
  .page_default dl.company_dl dd + dt {
    margin-top: 10px; }
  .page_default .block_box {
    display: flex;
    flex-flow: wrap row;
    justify-content: space-between;
    margin-bottom: 20px; }
    .page_default .block_box .block_col {
      text-align: center;
      width: calc(100% / 2 - 5px); }
      .page_default .block_box .block_col a {
        border: none; }
      .page_default .block_box .block_col:nth-child(n+3) {
        margin-top: 5px; }
      .page_default .block_box .block_col .item_cap {
        font-size: 1.2rem;
        margin-top: 3px; }
  .page_default .fix_bg01 {
    background-image: url(../images/bg_fiximg02.png);
    background-size: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    margin-bottom: 50px;
    margin-top: 30px;
    margin-left: calc(((100vw - 100%) / 2) * -1);
    margin-right: calc(((100vw - 100%) / 2) * -1);
    padding: 0px calc((100vw - 100%) / 2); }
    .page_default .fix_bg01:before {
      content: "";
      display: block;
      padding-top: 62.5%; }

@media only screen and (min-width: 767px) {
  .page_default {
    max-width: 1080px;
    margin: 0 auto; }
    .page_default h2 {
      font-size: 2.8rem;
      margin-bottom: 40px; }
    .page_default dl.company_dl {
      clear: both; }
      .page_default dl.company_dl dt {
        float: left; }
      .page_default dl.company_dl dd {
        margin-left: 12em;
        margin-bottom: 10px; }
        .page_default dl.company_dl dd + dt {
          margin-top: 0px; }
    .page_default .block_box {
      margin-bottom: 40px; }
      .page_default .block_box .block_col {
        width: calc(100% / 5 - 32px); }
        .page_default .block_box .block_col:nth-child(n+3) {
          margin-top: 0px; }
        .page_default .block_box .block_col:nth-child(n+6) {
          margin-top: 30px; }
        .page_default .block_box .block_col .item_cap {
          font-size: 1.4rem;
          margin-top: 6px; }
    .page_default .fix_bg01 {
      background-image: url(../images/bg_fiximg02.png);
      background-attachment: fixed;
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      height: 400px;
      margin-bottom: 80px;
      margin-top: 80px;
      margin-left: calc(((100vw - 100%) / 2) * -1);
      margin-right: calc(((100vw - 100%) / 2) * -1);
      padding: 0px calc((100vw - 100%) / 2); }
      .page_default .fix_bg01:before {
        display: none; } }

/* ––––––––––––––––––––––––––––––––––––––––––––––––––
404ページ
––––––––––––––––––––––––––––––––––––––––––––––––––*/
#paged_404 .wrap_title {
  margin-top: 0;
  text-align: center; }
  #paged_404 .wrap_title .item_404error {
    color: #004aab;
    font-size: 8rem;
    margin-top: 0;
    margin-bottom: 15px; }
  #paged_404 .wrap_title .item_title {
    font-size: 1.9rem;
    font-weight: 600;
    margin-bottom: 20px;
    margin-top: 0; }
  #paged_404 .wrap_title .item_img02 {
    margin-bottom: 30px; }
    #paged_404 .wrap_title .item_img02 img {
      max-width: 80%; }

#paged_404 .wrap_txt {
  margin-top: 0; }
  #paged_404 .wrap_txt .item_txt {
    margin-bottom: 20px; }
  #paged_404 .wrap_txt .item_link a {
    background-color: #0189d0;
    border: none;
    color: white;
    display: flex;
    align-items: center;
    font-size: 1.4rem;
    margin: 0;
    padding: 10px 15px; }
    #paged_404 .wrap_txt .item_link a .icon {
      font-size: 1.4rem;
      margin-left: auto; }

@media only screen and (min-width: 767px) {
  #paged_404 .wrap_title .item_404error {
    margin-bottom: 0px; }
  #paged_404 .wrap_title .item_title {
    font-size: 2.9rem;
    margin-bottom: 15px; }
  #paged_404 .wrap_title .item_img02 {
    margin-bottom: 40px; }
    #paged_404 .wrap_title .item_img02 img {
      max-width: 100%; }
  #paged_404 .wrap_txt {
    margin: 0 auto 30px;
    width: 800px; }
    #paged_404 .wrap_txt .item_txt {
      margin-bottom: 30px; }
    #paged_404 .wrap_txt .item_link {
      text-align: center; }
      #paged_404 .wrap_txt .item_link a {
        display: inline-flex;
        font-size: 1.6rem;
        padding: 15px; }
        #paged_404 .wrap_txt .item_link a .icon {
          margin-left: 20px; } }

/* ––––––––––––––––––––––––––––––––––––––––––––––––––
お問い合わせフォーム
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.wrap_contact .contact_table {
  border: none;
  border-bottom: solid 1px #e8e8d8;
  margin-bottom: 15px;
  margin-top: 0;
  width: 100%; }
  .wrap_contact .contact_table tr:nth-child(odd) {
    background-color: #eee; }
  .wrap_contact .contact_table th, .wrap_contact .contact_table td {
    border: none;
    padding: 10px; }
  .wrap_contact .contact_table th {
    background-color: inherit;
    display: block;
    padding-bottom: 0; }
    .wrap_contact .contact_table th.block_th {
      display: flex;
      align-items: center;
      justify-content: space-between; }
      .wrap_contact .contact_table th.block_th span {
        font-size: 1.3rem;
        font-weight: 500; }
        .wrap_contact .contact_table th.block_th span.item_txt {
          font-size: 1.4rem;
          font-weight: 600; }
        .wrap_contact .contact_table th.block_th span.item_any, .wrap_contact .contact_table th.block_th span.item_need {
          border-radius: 3px;
          padding: 3px 10px; }
        .wrap_contact .contact_table th.block_th span.item_any {
          border: solid 1px #bbbbbb;
          color: #666666; }
        .wrap_contact .contact_table th.block_th span.item_need {
          background-color: #e50012;
          color: white; }
  .wrap_contact .contact_table td {
    display: block; }
    .wrap_contact .contact_table td label {
      width: 100%; }
  .wrap_contact .contact_table input, .wrap_contact .contact_table textarea, .wrap_contact .contact_table select {
    background-color: white;
    border: solid 1px #bbbbbb;
    border-radius: 5px;
    font-size: 1.6rem;
    padding: 8px 15px;
    -webkit-appearance: none;
    width: 100%; }
  .wrap_contact .contact_table .wpcf7-list-item {
    background-color: white;
    border: solid 1px #bbbbbb;
    border-radius: 6px;
    display: flex;
    align-items: center;
    margin: 0 0 5px;
    padding: 8px; }
    .wrap_contact .contact_table .wpcf7-list-item:last-child {
      margin-bottom: 0; }
    .wrap_contact .contact_table .wpcf7-list-item .wpcf7-list-item-label {
      font-size: 1.6rem;
      order: 2; }
    .wrap_contact .contact_table .wpcf7-list-item input[type="checkbox"] {
      background: #fff;
      border: solid 1px #bbbbbb;
      border-radius: 4px;
      height: 24px !important;
      margin: 0;
      margin-right: 10px;
      outline: none;
      padding: 0;
      position: relative;
      -webkit-appearance: none;
      width: 24px !important; }
      .wrap_contact .contact_table .wpcf7-list-item input[type="checkbox"]:checked {
        outline: none; }
        .wrap_contact .contact_table .wpcf7-list-item input[type="checkbox"]:checked:before {
          background: #f6aa00;
          content: "";
          display: block;
          height: 16px;
          position: absolute;
          top: 50%;
          left: 50%;
          -webkit-transform: translate(-50%, -50%);
          -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
          width: 16px; }
    .wrap_contact .contact_table .wpcf7-list-item input[type="radio"] {
      background: #fff;
      border: solid 1px #bbbbbb;
      border-radius: 100%;
      height: 24px !important;
      margin: 0;
      margin-right: 10px;
      outline: none;
      padding: 0;
      position: relative;
      -webkit-appearance: none;
      width: 24px !important; }
      .wrap_contact .contact_table .wpcf7-list-item input[type="radio"]:checked:before {
        background: #f6aa00;
        border-radius: 100%;
        content: "";
        display: block;
        height: 16px;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        width: 16px; }
  .wrap_contact .contact_table select {
    appearance: none;
    background: white url(../images/icon_select-arrowb.svg) no-repeat calc(100% - 10px) 50%/18px;
    cursor: pointer;
    margin: 0px;
    padding: 8px 30px 8px 10px;
    -webkit-appearance: none;
    -moz-appearance: none;
    position: relative; }

.wrap_contact .item_confirmtxt {
  border: double 4px #ccc;
  border-radius: 5px;
  margin: 0 auto 15px;
  padding: 25px 30px;
  width: 100%; }

.wrap_contact .item_checkbtn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
  text-align: center; }
  .wrap_contact .item_checkbtn input[type="checkbox"] {
    background: #fff;
    border: solid 1px #bbbbbb;
    border-radius: 6px;
    height: 24px;
    margin-right: 10px;
    outline: none;
    position: relative;
    vertical-align: -8px;
    -webkit-appearance: none;
    width: 24px; }
    .wrap_contact .item_checkbtn input[type="checkbox"]:checked {
      background: #f6aa00;
      border: 1px solid #fff;
      outline: none; }
      .wrap_contact .item_checkbtn input[type="checkbox"]:checked:before {
        background: #ffffff;
        content: "";
        display: block;
        height: 3px;
        position: absolute;
        left: 1px;
        top: 14px;
        -webkit-transform: rotate(45deg);
        -webkit-transform-origin: right center;
        width: 10px; }
      .wrap_contact .item_checkbtn input[type="checkbox"]:checked:after {
        display: block;
        position: absolute;
        left: 9px;
        top: 14px;
        content: "";
        width: 16px;
        height: 3px;
        background: #ffffff;
        -webkit-transform: rotate(-53deg);
        -webkit-transform-origin: left center; }

.wrap_contact span.wpcf7-not-valid-tip {
  color: #f00;
  font-size: 1.2rem;
  display: block; }

.wrap_contact .item_submit {
  text-align: center;
  padding: 0 10px 10px; }
  .wrap_contact .item_submit input {
    background-color: #f6aa00;
    border: none;
    border-radius: 6px;
    color: white;
    cursor: pointer;
    font-size: 1.6rem;
    height: 64px;
    padding: 1rem;
    width: 100%;
    -webkit-appearance: none; }
    .wrap_contact .item_submit input[disabled] {
      background-color: #d6d6d6;
      cursor: inherit; }

div.wpcf7-validation-errors {
  background-color: #b83232;
  border: none !important;
  color: white;
  margin-left: 0 !important;
  margin-right: 0 !important; }

@media only screen and (min-width: 767px) {
  .wrap_contact {
    padding: 20px 0; }
    .wrap_contact .contact_table {
      margin-bottom: 25px; }
      .wrap_contact .contact_table th, .wrap_contact .contact_table td {
        display: table-cell;
        padding: 10px 10px 10px 15px; }
      .wrap_contact .contact_table th.block_th {
        display: table-cell;
        position: relative;
        text-align: left;
        vertical-align: top;
        width: 325px; }
        .wrap_contact .contact_table th.block_th span {
          font-size: 1.6rem; }
          .wrap_contact .contact_table th.block_th span.item_txt {
            display: inline-block;
            font-size: 1.5rem;
            margin-right: 40px;
            padding: 8px 0; }
          .wrap_contact .contact_table th.block_th span.item_any, .wrap_contact .contact_table th.block_th span.item_need {
            font-size: 1.4rem;
            padding: 5px 10px;
            position: absolute;
            right: 0;
            top: 12px; }
          .wrap_contact .contact_table th.block_th span.item_need {
            font-weight: 500; }
      .wrap_contact .contact_table td {
        vertical-align: text-bottom; }
      .wrap_contact .contact_table input, .wrap_contact .contact_table textarea, .wrap_contact .contact_table select {
        width: auto; }
      .wrap_contact .contact_table .wpcf7-list-item {
        background-color: inherit;
        border: none;
        padding: 0px; }
    .wrap_contact .item_confirmtxt {
      margin: 0 auto 20px;
      padding: 15px;
      width: 100%; }
    .wrap_contact .item_submit {
      text-align: center;
      margin-left: auto;
      margin-right: auto;
      padding: 0 10px;
      width: 300px; }
      .wrap_contact .item_submit input {
        height: 60px; } }

/* ––––––––––––––––––––––––––––––––––––––––––––––––––
お問い合わせありがとうページ
––––––––––––––––––––––––––––––––––––––––––––––––––*/
#paged_thanks .wrap_title {
  background-image: url(../images/page_thanks-img01.png), url(../images/page_thanks-img02.png);
  background-repeat: no-repeat,no-repeat;
  background-size: 113px,113px;
  background-position: left top,right top;
  text-align: center; }
  #paged_thanks .wrap_title .item_title {
    font-size: 1.9rem;
    font-weight: 600;
    margin-bottom: 20px;
    padding-top: 0px; }

#paged_thanks .wrap_caution .inner_box {
  background-color: #e8e8d8;
  margin-bottom: 15px;
  padding: 20px; }

#paged_thanks .wrap_txt .item_txt {
  margin-bottom: 30px; }

#paged_thanks .wrap_txt .item_link a {
  background-color: #e8392d;
  border: none;
  border-radius: 6px;
  color: white;
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  margin: 0;
  padding: 10px 15px; }
  #paged_thanks .wrap_txt .item_link a .icon {
    font-size: 1.4rem;
    margin-left: auto; }

@media only screen and (min-width: 767px) {
  #paged_thanks .wrap_title {
    background-size: auto,auto;
    background-position: 115px top,680px 0; }
    #paged_thanks .wrap_title .item_title {
      font-size: 2.9rem;
      padding-top: 30px; }
  #paged_thanks .wrap_caution {
    margin: 0 auto 30px;
    width: 800px; }
    #paged_thanks .wrap_caution .inner_box {
      margin-bottom: 30px;
      padding: 20px; }
  #paged_thanks .wrap_txt {
    margin: 0 auto;
    width: 800px; }
    #paged_thanks .wrap_txt .item_txt {
      margin-bottom: 30px; }
    #paged_thanks .wrap_txt .item_link {
      text-align: center; }
      #paged_thanks .wrap_txt .item_link a {
        display: inline-flex;
        font-size: 1.6rem;
        padding: 15px; }
        #paged_thanks .wrap_txt .item_link a .icon {
          margin-left: 20px; } }

/* ––––––––––––––––––––––––––––––––––––––––––––––––––
sns一覧カスタマイズ
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.wrap_sns {
  display: flex;
  justify-content: space-between; }
  .wrap_sns .inner_sns {
    border: none;
    display: inline-block; }
    .wrap_sns .inner_sns .block_sns {
      background: #eee;
      border-radius: 100%;
      display: block;
      text-align: center;
      height: 40px;
      line-height: 45px;
      width: 40px;
      transition: .8s;
      transform: rotateY(0deg); }
    .wrap_sns .inner_sns span {
      font-size: 1.8rem; }
      .wrap_sns .inner_sns span.icon-twitter {
        color: #55acee; }
      .wrap_sns .inner_sns span.icon-facebook {
        color: #4266b2; }
      .wrap_sns .inner_sns span.icon-google-plus {
        color: #dd4b39; }
      .wrap_sns .inner_sns span.icon-pocket {
        color: #ee4256; }
      .wrap_sns .inner_sns span.icon-hatebu {
        color: #008fde; }
      .wrap_sns .inner_sns span.icon-line {
        color: #00c300; }

@media only screen and (min-width: 767px) {
  .wrap_sns {
    justify-content: flex-start; }
    .wrap_sns .inner_sns {
      margin-right: 10px; } }
